$glow-color: #fff;
$glow-color-primary: #ff20af;
$glow-color-secondary: #0abab5;

.glowing-light {
  text-shadow: 0px 0px 30px $glow-color;
  color: $glow-color;
  animation: glowingLight 5s infinite;
}

.glowing-light-primary {
  text-shadow: 0px 0px 30px $glow-color-primary;
  color: $glow-color-primary;
  animation: glowingLightPrimary 5s infinite;
}

.glowing-light-secondary {
  text-shadow: 0px 0px 30px $glow-color-secondary;
  color: $glow-color-secondary;
}

@keyframes glowingLight {
  0% {
    text-shadow: 0px 0px 20px $glow-color;
  }
  25% {
    text-shadow: 0px 0px 30px $glow-color;
  }
  50% {
    text-shadow: 0px 0px 40px $glow-color;
  }
  75% {
    text-shadow: 0px 0px 30px $glow-color;
  }
  100% {
    text-shadow: 0px 0px 20px $glow-color;
  }
}

@keyframes glowingLightPrimary {
  0% {
    text-shadow: 0px 0px 20px $glow-color-primary;
  }
  25% {
    text-shadow: 0px 0px 30px $glow-color-primary;
  }
  50% {
    text-shadow: 0px 0px 40px $glow-color-primary;
  }
  75% {
    text-shadow: 0px 0px 30px $glow-color-primary;
  }
  100% {
    text-shadow: 0px 0px 20px $glow-color-primary;
  }
}
