@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-Italic.eot");
  src: local("Styrene A Web Italic"), local("StyreneAWeb-Italic"),
    url("./fonts/StyreneAWeb-Italic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-Italic.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-Italic.woff") format("woff"),
    url("./fonts/StyreneAWeb-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-LightItalic.eot");
  src: local("Styrene A Web Light Italic"), local("StyreneAWeb-LightItalic"),
    url("./fonts/StyreneAWeb-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/StyreneAWeb-LightItalic.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-LightItalic.woff") format("woff"),
    url("./fonts/StyreneAWeb-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-Thin.eot");
  src: local("Styrene A Web Thin"), local("StyreneAWeb-Thin"),
    url("./fonts/StyreneAWeb-Thin.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-Thin.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-Thin.woff") format("woff"),
    url("./fonts/StyreneAWeb-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-BoldItalic.eot");
  src: local("Styrene A Web Bold Italic"), local("StyreneAWeb-BoldItalic"),
    url("./fonts/StyreneAWeb-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-BoldItalic.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-BoldItalic.woff") format("woff"),
    url("./fonts/StyreneAWeb-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-BlackItalic.eot");
  src: local("Styrene A Web Black Italic"), local("StyreneAWeb-BlackItalic"),
    url("./fonts/StyreneAWeb-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/StyreneAWeb-BlackItalic.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-BlackItalic.woff") format("woff"),
    url("./fonts/StyreneAWeb-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-Regular.eot");
  src: local("Styrene A Web Regular"), local("StyreneAWeb-Regular"),
    url("./fonts/StyreneAWeb-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-Regular.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-Regular.woff") format("woff"),
    url("./fonts/StyreneAWeb-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-Black.eot");
  src: local("Styrene A Web Black"), local("StyreneAWeb-Black"),
    url("./fonts/StyreneAWeb-Black.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-Black.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-Black.woff") format("woff"),
    url("./fonts/StyreneAWeb-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-Medium.eot");
  src: local("Styrene A Web Medium"), local("StyreneAWeb-Medium"),
    url("./fonts/StyreneAWeb-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-Medium.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-Medium.woff") format("woff"),
    url("./fonts/StyreneAWeb-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-ThinItalic.eot");
  src: local("Styrene A Web Thin Italic"), local("StyreneAWeb-ThinItalic"),
    url("./fonts/StyreneAWeb-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-ThinItalic.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-ThinItalic.woff") format("woff"),
    url("./fonts/StyreneAWeb-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-Bold.eot");
  src: local("Styrene A Web Bold"), local("StyreneAWeb-Bold"),
    url("./fonts/StyreneAWeb-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-Bold.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-Bold.woff") format("woff"),
    url("./fonts/StyreneAWeb-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-Light.eot");
  src: local("Styrene A Web Light"), local("StyreneAWeb-Light"),
    url("./fonts/StyreneAWeb-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/StyreneAWeb-Light.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-Light.woff") format("woff"),
    url("./fonts/StyreneAWeb-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("./fonts/StyreneAWeb-MediumItalic.eot");
  src: local("Styrene A Web Medium Italic"), local("StyreneAWeb-MediumItalic"),
    url("./fonts/StyreneAWeb-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/StyreneAWeb-MediumItalic.woff2") format("woff2"),
    url("./fonts/StyreneAWeb-MediumItalic.woff") format("woff"),
    url("./fonts/StyreneAWeb-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

$logoFamily: "Montserrat", sans-serif;
$titleFmaily: "Styrene A Web";
$subTitleFmaily: "Styrene A Web";
$txtFamily: "Arial";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $txt;
  font-weight: 500;
}

h1 {
  font-family: $logoFamily;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
}

h2 {
  font-family: $titleFmaily;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
}

h4,
h5 {
  font-family: $subTitleFmaily;
  letter-spacing: 0.5px;
}

p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 0px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 20px;
}

.button-txt {
  font-family: "Times-new-roman";
  font-size: 28px;
  letter-spacing: 1.5px;
  margin-top: 18px;
}

.ant-notification-notice-description,
.ant-notification-notice-message {
  font-family: $txtFamily;
}

.form-text {
  color: grey;
}

.rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.rotated-invert {
  writing-mode: vertical-rl;
  transform: rotate(0);
}

.txt-gradient {
  background: -webkit-linear-gradient(left, $primary, $secondary);
  background: -o-linear-gradient(right, $primary, $secondary);
  background: -moz-linear-gradient(right, $primary, $secondary);
  background: linear-gradient(to right, $primary, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-dark-gradient {
  background: -webkit-linear-gradient(left, $primary-dark, $secondary);
  background: -o-linear-gradient(right, $primary-dark, $secondary);
  background: -moz-linear-gradient(right, $primary-dark, $secondary);
  background: linear-gradient(to right, $primary-dark, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
  .rotated {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
}
